﻿module.exports = (function () {
    if (document.getElementById('spoDropdown')) {
        document.getElementById('spoDropdown').addEventListener('mouseover', spoDropdownFocus);

        const spoMegaMenu = document.querySelector('.spo-nav-container');
        document.addEventListener('mouseover', function handleClickOutsideBox(event) {
            if (!spoMegaMenu.contains(event.target) && event.target.id != 'spoDropdown') {
                spoMegaMenu.classList.remove('open');
                document.getElementById('spoDropdown').classList.remove('open');
            }
        });

        document.getElementById('spoDropdown').addEventListener('click', function (e) {
            if (document.getElementById('spoDropdown').dataset.dropdownLink) window.location.href = document.getElementById('spoDropdown').dataset.dropdownLink;
        });
    }

    if (document.getElementById('vipDropdown')) {
        document.getElementById('vipDropdown').addEventListener('mouseover', vipDropdownFocus);

        const vipMenuContainer = document.getElementsByClassName('vip-nav-container');

        document.addEventListener('mouseover', function handleClickOutsideBox(event) {
            if (!vipMenuContainer[0].contains(event.target) && event.target.id != 'vipDropdown') {
                vipMenuContainer[0].classList.remove('open');
                document.getElementById('vipDropdown').classList.remove('open');
            }
        });

        document.getElementById('vipDropdown').addEventListener('click', function (e) {
            if (document.getElementById('vipDropdown').dataset.dropdownLink) window.location.href = document.getElementById('vipDropdown').dataset.dropdownLink;
        });
    }

    function closeByClass(c) {
        const className = document.getElementsByClassName(c);
        if (className) {
            className[0].classList.remove('open');
            className[0].classList.add('collapsed');
        }
    }

    function closeSearch() {
        document.body.classList.remove('search-open');
        closeByClass('search');
        closeByClass('search-toggle');
        closeByClass('search-links');
        closeByClass('search-results');
    }

    function spoDropdownFocus() {
        if (document.getElementById('vipDropdown')) document.getElementById('vipDropdown').classList.remove('open');

        const vipMenuContainers = document.getElementsByClassName('vip-nav-container');
        Array.prototype.forEach.call(vipMenuContainers, function (el) {
            el.classList.remove('open');
        });

        closeSearch();

        const spoMenuContainer = document.getElementsByClassName("spo-nav-container");
        spoMenuContainer[0].classList.add('open');
        document.getElementById('spoDropdown').classList.add('open');
    }

    function vipDropdownFocus() {
        if (document.getElementById('spoDropdown')) document.getElementById('spoDropdown').classList.remove('open');

        const spoMenuContainer = document.getElementsByClassName('spo-nav-container');
        if (spoMenuContainer[0]) {
            spoMenuContainer[0].classList.remove('open');
        }

        closeSearch();

        const vipMenuContainer = document.getElementsByClassName('vip-nav-container');
        vipMenuContainer[0].classList.add('open');
        document.getElementById('vipDropdown').classList.add('open');
    }
    
});

