﻿/*
  app.js
  Global Script for project included on every page.
*/

// common libraries used on enough shared pages
require('../../ga/scripts/partials/libs');
// mobile navigation handler
require('../../ga/scripts/partials/mobilenav')();
// Side navigation
const sidenav = require('../../ga/scripts/partials/sidenav');
// spo dropdown - original
require('../../ga/scripts/partials/spo');
// spo dropdown - new
require('../../ga/scripts/partials/spodropdown')();

// site search handler. takes one param: true or false to control if auto open or not
const search = require('../../ga/scripts/partials/simonsearch')(false);
const appendToSpoLinks = require('../../ga/scripts/partials/linkAppender');
const cookiesUtil = require('../../ga/scripts/partials/cookies');

// floating labels handler for all forms pages
const floatingLabels = require('../../ga/scripts/partials/floatinglabels');

const runCaptcha = require('../../ga/scripts/partials/recaptchasubmit');
const openModal = require('../../ga/scripts/partials/modals');

// spo ad animation
const runSpoAd = () => {
  const spoAdLinkBg = document.querySelector('.spo-ad-link-bg');
  const searchAdLinkBg = document.querySelector('#simon > .navbar .simon-search-ad-link-bg');
  const spoAdLinkMobileBg = document.querySelector('.navbar-toggle .spo-ad-link-bg');
  const spoAd = document.querySelector('.spo-ad');
  const spoAdHeader = document.querySelector('.spo-ad-header');
  const elementsToFadeIn = [...document.querySelectorAll('.spo-ad-text')];
  const whichTextToType = document.querySelector('.js-simon-search-banner-ad') ? 'NOW LIVE.' : 'SHOP SIMON';

  const openAd = () => {
    if(spoAdLinkBg) spoAdLinkBg.classList.add('expandUp');
    if(searchAdLinkBg) searchAdLinkBg.classList.add('expandUp');
    if(spoAdLinkMobileBg) spoAdLinkMobileBg.classList.add('expandUp');
    spoAd.classList.add('expandAndSlideDown');
    setTimeout(() => {
      if(spoAdLinkMobileBg) spoAdLinkMobileBg.previousElementSibling.classList.add('black');
    }, 500);
  };

  const closeAd = () => {
    if(spoAdLinkBg) spoAdLinkBg.classList.add('expandDown');
    if(searchAdLinkBg) searchAdLinkBg.classList.add('expandDown');
    if(spoAdLinkMobileBg) spoAdLinkMobileBg.classList.add('expandDown');
    spoAd.classList.add('slideUp');
    setTimeout(() => {
      if(spoAdLinkMobileBg) spoAdLinkMobileBg.previousElementSibling.classList.remove('black');
      document.querySelector('.spo-ad').parentNode.removeChild(document.querySelector('.spo-ad'));
    }, 1000);
  };

  const fadeInElements = (els) => {
    for(let i = 0; i < els.length; i++) {
      setTimeout(function () {
        els[i].classList.add('fadeIn');
      }, i * 400);
    }
  };

  const typeText = (i, textToType) => {
    const typeWriter = (text, i2, fnCallback) => {
      if(i < text.length) {
        spoAdHeader.innerHTML =
          text.slice(0, i2 + 1) + ((i2 >= text.length - 1) ? '<sup>™</sup>' : '') + '<span aria-hidden=\'true\'></span>';

        setTimeout(function () {
          typeWriter(text, i2 + 1, fnCallback);
        }, 100);
      }
    };

    if(i < textToType.length) {
      typeWriter(textToType, 0, function () {
        typeText(i + 1, textToType);
      });
    }
  };

  const animateAd = (animationSteps) => {
    const steps = [...animationSteps];
    const firstStep = steps.shift();
    firstStep();

    const animationChain = window.setInterval(() => {
      if(steps.length > 0) {
        const nextStep = steps.shift();
        window.requestAnimationFrame(nextStep);
      } else {
        window.clearInterval(animationChain);
      }
    }, 1850);
  };

  // animate the ad
  animateAd([
    openAd,
    typeText.bind(null, 0, whichTextToType),
    fadeInElements.bind(null, elementsToFadeIn),
    () => {},
    closeAd
  ]);
};

const vipUser = JSON.parse(window.sessionStorage.getItem('vipUser'));


function smartBannerOnMontreal() {
  const smartBanner = document.getElementById('smartbanner');

  if(smartBanner) {
    document.getElementById('simon').classList.add('sbActive');
  }
}

(function () {
  // check url for string param and return its value if any
  const getParameterByName = function (name) {
    const url = window.location.href;
    const cleanName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + cleanName + '(=([^&#]*)|&|#|$)');
    const regExResults = regex.exec(url);
    if(!regExResults) {
      return null;
    }
    if(!regExResults[2]) {
      return '';
    }
    return decodeURIComponent(regExResults[2].replace(/\+/g, ' '));
  };

  const vipLoginForm = document.getElementById('login');
  if(vipLoginForm) {
    runCaptcha('login', 'vip_loginDropdown');
  }

  // doc ready
  $(document).ready(function () {
    const isAndroid = /Android/i.test(navigator.userAgent);
    // if there are floating labels init floating labels
    if($('label.floating').length > 0) {
      floatingLabels();
    }

    if(!cookiesUtil.read('spoAdSeen') && document.querySelector('.js-shop-online')) {
      // run the ad
      runSpoAd();
      cookiesUtil.write('spoAdSeen', 'true', 7);
    } else {
      if(isAndroid && getParameterByName('noframe') === null && !document.body.classList.contains('mall-map-view')) {
        $.smartbanner({
          title: 'SIMON APP',
          author: 'Simon Property Group',
          price: 'FREE',
          appStoreLanguage: 'us',
          inAppStore: 'On the App Store',
          inGooglePlay: 'In Google Play',
          icon: 'https://assets.simon.com/SimonWebAssets_Images/favicons/android-chrome-48x48.png',
          iconGloss: null,
          button: 'DOWNLOAD',
          scale: 'auto',
          speedIn: 300,
          speedOut: 400,
          daysHidden: 15,
          daysReminder: 30,
          force: 'android',
          layer: true
        });
      }
    }

    if(document.location.href.indexOf('premiumoutlets') >= 0) {
      appendToSpoLinks();
    } else {
      if(!cookiesUtil.read('simonSearchAdSeen') && document.querySelector('.js-simon-search-banner-ad') && document.location.pathname.indexOf('search') === -1) {
        runSpoAd();
        cookiesUtil.write('simonSearchAdSeen', 'true', 7);
      }

      if(isAndroid && getParameterByName('noframe') === null && !document.body.classList.contains('mall-map-view')) {
        $.smartbanner({
          title: 'SIMON APP',
          author: 'Simon Property Group',
          price: 'FREE',
          appStoreLanguage: 'us',
          inAppStore: 'On the App Store',
          inGooglePlay: 'In Google Play',
          icon: 'https://assets.simon.com/SimonWebAssets_Images/favicons/android-chrome-48x48.png',
          iconGloss: null,
          button: 'DOWNLOAD',
          scale: 'auto',
          speedIn: 300,
          speedOut: 400,
          daysHidden: 15,
          daysReminder: 30,
          force: 'android',
          layer: true
        });
      }
    }

    // Session Storage
    window.memoryStorage = {};

    function isEmpty(o) {
      let i;
      for(i in o) {
        if(o.hasOwnProperty(i)) {
          return false;
        }
      }
      return true;
    }

    if(isEmpty(memoryStorage)) {
      // Ask other tabs for memstorage
      localStorage.setItem('getSessionStorage', Date.now());
    }

    window.addEventListener('storage', function (event) {
      const data = JSON.parse(event.newValue);
      if(sessionStorage.length > 0) {
        // console.log('storage event', event);
        if(event.key === 'getSessionStorage') {
          localStorage.setItem('sessionStorage', JSON.stringify(memoryStorage));
          localStorage.removeItem('sessionStorage');
        } else if(event.key === 'sessionStorage' && isEmpty(memoryStorage)) {
          for(key in data) {
            if(data.hasOwnProperty(key)) {
              memoryStorage[key] = data[key];
            }
          }
          // showSessionStorage();
        }
      }
    });
    // SessionStorage END

    smartBannerOnMontreal();

    // open search if on homepage, 404 page or search page not /brand. Excludes product search pages (*/*/*/search)
    // TODO: This is janky, need to find a better way but moving it up to higher app level for easier global control
    if($('.error-404').length > 0 || (document.location.href.indexOf('/search') >= 0 && document.location.href.indexOf('/brand/') === -1 && document.location.href.indexOf('/montrealpremiumoutlets/') === -1 && document.location.pathname.split('/')[3] !== 'search' && document.location.pathname.split('/')[3] !== 'search' && document.location.pathname.split('/')[3] !== 'map') || document.querySelector('.homepage-content') !== null || document.querySelector('.travel-regional') !== null || (document.querySelector('.simon-search-open') !== null && window.innerWidth > 992)) {
      search.openSearchBox($('.search-toggle'));
      search.openSearchBox($('.search'));
    }

    if(document.getElementById('smartbanner')) {
      const closeSmartBannerButtons = document.querySelectorAll('.sb-close');

      closeSmartBannerButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          document.getElementById('simon').classList.remove('sbActive');
        });
      });
    }

    if(document.getElementById('footerSignupButton')) {
      document.getElementById('footerSignupButton').addEventListener('click', function (e) {
        e.preventDefault();
        let emailAddress;
        const separator = '?';
        let mallId = '';
        if(window.mallObj) {
          mallId = window.mallObj.Id ? '&mallId=' + window.mallObj.Id : '';
        }

        if(document.getElementById('emailAddressFooter').value === '') {
          emailAddress = '';
        } else {
          emailAddress = 'email=' + encodeURIComponent(document.getElementById('emailAddressFooter').value);
        }

        if(typeof ga !== 'undefined') {
          if(window.location.href.indexOf('premiumoutlets') >= 0) {
            ga.getAll()[0].send('event', {
              eventCategory: 'VIP Email Footer',
              eventAction: 'User Submission',
              eventLabel: window.location.href
            });
          } else {
            ga.getAll()[0].send('event', {
              eventCategory: 'Mall Insider Email Footer',
              eventAction: 'User Submission',
              eventLabel: window.location.href
            });
          }
        }

        if(window.location.href.indexOf('premiumoutlets') >= 0) {
          if(vipUser) {
            // console.log("User is present");
            window.location.assign('/vip/centers');
          } else {
            // console.log("User not found.");
            window.location.assign('/vip/register' + separator + emailAddress + '&source=footer');
          }
        } else {
          // console.log("Mall Insider...");
          window.location.assign('/mall-insider' + separator + emailAddress + '&source=footer');
        }
      });
    }

    if(document.getElementById('wifiSuccessModal')) {
      openModal('wifiSuccessModal');
    }

    if(document.getElementById('sideNavBurger')) {
      document.getElementById('sideNavBurger').addEventListener('click', sidenav.open);
    }
    if(document.getElementById('sideNavBurger')) {
      document.getElementById('globalSidenavClose').addEventListener('click', sidenav.close);
      document.addEventListener('click', e => {
        if(document.getElementById('globalSidenav') && !document.getElementById('globalSidenav').contains(e.target) && !document.getElementById('sideNavBurger').contains(e.target)) {
          sidenav.close();
        }
      });
    }
  });

  // check if there is geolocation capabilities in the browser
  // if not, hide find malls by location
  if(!('geolocation' in navigator)) {
    $('.js-find-nearby').addClass('hidden-xs-up');
  }

  // if there are lazy loaded images: unhide img and run lazyload function
  if($('img.img-lazy').length > 0 && document.querySelector('.content-stream-grid') === null) {
    $('img.img-lazy').removeClass('hidden-xs-up').lazyload({
      effect: 'fadeIn',
      failure_limit: 6
    });
  }

  // vip login dropdown
  // if there is a dropdown and a cookie is available with user data

  if($('.vip-dropdown').length > 0 && vipUser) {
    // show the logged in state
    $('.logged-in').removeClass('hidden-xs-up');
    // remove the not logged in state
    $('.no-login').remove();
    // add username from cookie obj
    $('.userName').append(`${vipUser.FirstName} ${vipUser.LastName}`);
    // add preferred center from cookie obj
    /* $('.centerName').append(vipUser.CenterIds[0].OutletName); */
    document.querySelector('.centerName').innerHTML = document.querySelector('.centerName').innerHTML.replace(/[Â]/g, '').replace(/[®]/g, '<sup>&reg;</sup>');
  } else {
    // else the user is not logged in so hide logged in state
    $('.logged-in').addClass('hidden-xs-up');
  }

  let prevScrollpos = window.pageYOffset;
  $(window).scroll(function () {
    const mobileNavBarHeight = '-' + $('#mobileBottomNav').css('height');
    const currentScrollPos = window.pageYOffset;
    const scrollThreshold = 16;

    if(prevScrollpos > currentScrollPos + scrollThreshold) {
      $('#mobileBottomNav').css('bottom', 0);
      $('#mobileBottomNav').css('box-shadow', '0px 0px 20px rgba(0,0,0,0.25)');
      prevScrollpos = currentScrollPos;
    } else if(prevScrollpos < currentScrollPos - scrollThreshold) {
      $('#mobileBottomNav').css('bottom', mobileNavBarHeight);
      $('#mobileBottomNav').css('box-shadow', 'none');
      prevScrollpos = currentScrollPos;
    }
  });
})();
