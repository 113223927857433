﻿module.exports.open = openSidenav;
module.exports.close = closeSidenav;
function openSidenav() {
    document.getElementById("globalSidenav").style.left = "0";
    document.getElementById("sideNavOverlay").style.opacity = 1;
    document.getElementById("sideNavOverlay").style.display = "block";

    document.body.classList.remove('search-open');
    closeByClass("search");
    closeByClass("search-toggle");
    closeByClass("search-links");
    closeByClass("search-results");
    if (window.innerWidth < 992 && $('.search-toggle.hidden-lg-up .icon-search').length) {
        changeSvg('.search-toggle.hidden-lg-up .icon-search', '#icon-search');
    }
}

function closeSidenav() {
    document.getElementById("globalSidenav").style.left = "-9999px";
    document.getElementById("sideNavOverlay").style.opacity = 0;
    setTimeout(() => { document.getElementById("sideNavOverlay").style.display = "none" },200);
}

function closeByClass(c) {
    const className = document.getElementsByClassName(c);
    if (className) {
        Array.prototype.forEach.call(className, function (el) {
            el.classList.remove("open");
            el.classList.add("collapsed");
        });
    }
}

const changeSvg = function changeSvg(origEl, newId) {
    $(origEl + ' use').prop('href').baseVal = newId;
};